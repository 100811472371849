import React from 'react';

import SEO from '../components/seo';
import Layout from "../components/layout"
import Reservation from "../components/reservation"

const ACTIVE_BUTTON = 'inline-block border border-red-500 rounded py-1 px-3 bg-red-500 text-white'
const INACTIVE_BUTTON = 'inline-block border border-white rounded hover:border-gray-200 text-red-500 hover:bg-gray-200 py-1 px-3'

const Rezervace = props => {
	const [isReservation, setIsReservation] = React.useState(true)
	const toggleButton = () => setIsReservation(! isReservation)

	return (
		<>
			<SEO title="Rezervace & Kontakt" />
			<Layout>
				<SEO title="Rezervace & Kontakt" />
				<div className="container mx-auto p-8 mb-3">
					<h1 className="text-5xl text-gray-900">
						Rezervace & Kontakt
					</h1>

					{/*<div className="max-w-sm mx-auto flex justify-center mt-10">*/}
					{/*	<ul className="flex">*/}
					{/*		<li className="mr-3">*/}
					{/*			<a*/}
					{/*				className={ isReservation ? ACTIVE_BUTTON : INACTIVE_BUTTON }*/}
					{/*				onClick={ () => setIsReservation(true) }*/}
					{/*			>Rezervace</a>*/}
					{/*		</li>*/}
					{/*		<li className="mr-3">*/}
					{/*			<button*/}
					{/*				className={ isReservation ? INACTIVE_BUTTON : ACTIVE_BUTTON }*/}
					{/*				onClick={ () => setIsReservation(false) }*/}
					{/*			>Kontakt</button>*/}
					{/*		</li>*/}
					{/*	</ul>*/}
					{/*</div>*/}
					{ isReservation &&
						<Reservation />
					}

				</div>
			</Layout>
		</>
	);
};

export default Rezervace;
